import Cookies from "cookies-ts";
import jwt_decode from "jwt-decode";
import { UserType } from "@/types/authTypes";
const cookies = new Cookies();
const TOKEN = "AUTH_TOKEN";

const env = process.env;

export function setToken(token: string) {
  if (token) {
    const time = Number(Date.now());
    const decodeToken: UserType = jwt_decode(token);
    const expires =   Number(decodeToken.exp)*1000;
    const expiringTime = Math.floor((expires-time)/1000);
    expiringTime > 0 && cookies.set(TOKEN, token, { expires: expiringTime});
  }
}

export function logout() {
  return Promise.resolve(cookies.remove(TOKEN));
}

export function getToken() {
  return cookies.get(TOKEN);
}

export function isLoggedIn() {
  return getToken();
}

export function getUserid(): string {
  const user: { id: string } = jwt_decode(getToken() as string);
  return user.id;
}

export function encryptUserid(): string {
  const user: { id: string } = jwt_decode(getToken() as string);
  return user.id;
}

export function checkCookieExpiration(): boolean {
  if (getToken() == null) return true;
  const decoded: UserType = jwt_decode(getToken() as string);
  const expiringTimeInMilliseconds = decoded.exp * 1000 - Date.now();
  const expiringTimeInMinutes = expiringTimeInMilliseconds / (1000 * 60);
  if (expiringTimeInMinutes <= 2) return true;
  return false;
}
export function decodeToken(p0: string) {
  const decoded: UserType = jwt_decode(getToken() as string);
  return decoded;
}

export async function fetchToken() {
  try {
    const response = await fetch(`${env.VUE_APP_SSO}/login-token`, {
      method: "POST",
      credentials: "include"
    });
    return await response.json();
  } catch (error) {
    return;
  }
}