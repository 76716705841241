
/**
 * Encrypts the given ID and returns the encoded path.
 *
 * @param id The ID to be encrypted.
 * @returns The encoded path.
 */
const env = process.env;
const key = String(env.VUE_APP_ENCRYPTION_KEY);

export const encodeUrlPath = (id: string): string => {
  const path = `${key}${id}`;
  return encodeURIComponent(btoa(path));
};

/**
 * Decrypts the encoded URL and returns the decrypted value.
 *
 * @param {string} encodedUrl - The URL encoded string to be decrypted.
 * @return {string} The decrypted URL value.d
 */
export const decodeUrlPath = (encodedUrl: string | string[]): string => {
  const path = atob(decodeURIComponent(encodedUrl as string));
  const id = path.split(key)[1];
  return id;
};

export const formatTime = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes.toString().padStart(2, '0')}:${remainingSeconds
    .toString()
    .padStart(2, '0')}`;
};

export const truncateText = (text: string, to = 10) => {
  return text?.slice(0, to) + '...';
};

const maxLength = 50;
export function truncateString(inputString: string) {
  const plainText = (String(inputString)); //remove html tags before truncating
  if (plainText.length <= maxLength) {
    return plainText; // Return the original string if it's within or equal to the maxLength.
  } else {
    return plainText.slice(0, maxLength) + '...'; // Truncate and add ellipsis.
  }
}

